export default {
  data: function data() {
    return {
      tapAnima: false,
      list: [{
        t: "盘点计划生成",
        c: "可根据业务场景的需要，可实现在办公室电脑前进行一键盘点操作，也可人工手持RFID设备进行漫步式盘点。",
        img: require("@/assets/assets-manage/d/1.png")
      }, {
        t: "盘点清单",
        c: "数据实时汇总到系统后台，自动生成统计报表。",
        img: require("@/assets/assets-manage/d/2.png")
      }, {
        t: "智能盘点",
        c: "有多个可选性盘点工具：<br/>1.RFID手持设备批量盘点<br/>2.手机扫标签上的二维码盘点",
        img: require("@/assets/assets-manage/d/3.png")
      }, {
        t: "盘点结果数据生成",
        c: "自动生成资产统计报表，包括台账报表、盘点异常报表、财务表、流向表等，满足资产日常管理的需要。",
        img: require("@/assets/assets-manage/d/4.png")
      }],
      imgListCurrent: 0,
      imgList: [{
        t: "RFID手持设备批量盘点",
        img: require("@/assets/assets-manage/d/1.png")
      }, {
        t: "手机扫标签上的二维码",
        img: require("@/assets/assets-manage/d/2.png")
      }]
    };
  },
  methods: {
    imageClass: function imageClass(index) {
      var res;

      if (this.tapAnima) {
        res = "anima-yy";
      } else {
        res = index % 2 ? "anima-xx" : "anima-x";
      }

      return res;
    },
    num: function num(v) {
      v++;
      return v < 10 ? "0" + v : v;
    }
  },
  mounted: function mounted() {
    var _this = this;

    $(window).on("resize", function () {
      if (document.body.clientWidth < 1650) {
        _this.tapAnima = true;
      } else {
        _this.tapAnima = false;
      }
    });
  }
};