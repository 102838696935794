export default {
  data: function data() {
    return {
      list: [{
        img: require("@/assets/assets-manage/e/1.jpg"),
        t: "制造业",
        c: ["在制造业工厂，设备多、人员多、空间大、范围广，通过资产设备上的RFID，可以提供其位置、资产状态等信息，这些信息有助于提高资产利用率，减少资产闲置，避免重复采购。"]
      }, {
        img: require("@/assets/assets-manage/e/2.jpg"),
        t: "制造业",
        c: ["将RFID系统与企业现有的制造执行系统、制造信息管理系统通过“中间件”连接，厂商能够全/半自动数据采集，实时获得产品生产各环节信息，管理原材料、半成品、成品的流转，为企业制定合理的生产计划提供科学依据，大大提高生产力，节约生产成本。"]
      }, {
        img: require("@/assets/assets-manage/e/3.jpg"),
        t: "基建类",
        c: ["基础设施布建，使用RFID技术作为基建管理和数据采集方式。通过RFID电子标签和RFID阅读器的搭配，在门禁管理、资产盘点、资产进出等方面，自动化采集信息并上传数据到后台，减少人工操作，提高管理效率。"]
      }, {
        img: require("@/assets/assets-manage/e/4.jpg"),
        t: "重工",
        c: ["在重工行业，设备资产、工具资产等多种不同类型的资产，使用RFID管理进出、位置、维护、折旧、报废等，精细化管理各种资产的状态。"]
      }]
    };
  }
};