import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_top = _resolveComponent("top");

  var _component_assets_manage_b = _resolveComponent("assets-manage-b");

  var _component_assets_manage_c = _resolveComponent("assets-manage-c");

  var _component_assets_manage_d = _resolveComponent("assets-manage-d");

  var _component_common_recommend = _resolveComponent("common-recommend");

  var _component_assets_manage_e = _resolveComponent("assets-manage-e");

  var _component_common_list3 = _resolveComponent("common-list3");

  var _component_common_footer1 = _resolveComponent("common-footer1");

  var _component_common_back_top = _resolveComponent("common-back-top");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_top), _createVNode(_component_assets_manage_b), _createVNode(_component_assets_manage_c), _createVNode(_component_assets_manage_d), _createVNode(_component_common_recommend, {
    title: $data.recommend.title,
    list: $data.recommend.list
  }, null, 8, ["title", "list"]), _createVNode(_component_assets_manage_e, {
    class: "pc"
  }), _createVNode(_component_common_list3, {
    class: "mobile",
    title: "应用案例",
    list: $data.list,
    align: "left"
  }, null, 8, ["list"]), _createElementVNode("div", {
    style: _normalizeStyle({
      margin: '2rem 0 .4rem',
      height: '.02rem',
      background: '#E5E6EB'
    })
  }, null, 4), _createVNode(_component_common_footer1), _createVNode(_component_common_back_top)]);
}