export default {
  data: function data() {
    return {
      shouControls: false,
      current: 0,
      list: [{
        t: "RFID资产管理解决方案",
        video: require("@/assets/video.mp4")
      }, {
        t: "使用RFID进行工厂设备批量、快速盘点",
        video: require("@/assets/0dbd868909a8751536c5b9e481a83ac8.mp4")
      }, {
        t: "现场环境",
        video: require("@/assets/285a998807125901396dc6092d3369e9.mp4")
      }]
    };
  },
  computed: {
    ref: function ref(index) {
      return index === this.current ? "video" : "";
    }
  },
  methods: {
    play: function play() {
      var dom = document.getElementById("video-" + this.current);
      dom.play();
    },
    tap: function tap(index) {
      if (this.current === index) return; // let video = this.list[this.current].video;
      // this.list[this.current].video = "";
      // this.$nextTick(() => {
      //   this.list[this.current].video = video;
      // });

      var dom = document.getElementById("video-" + this.current);
      dom.pause();
      this.current = index;
      this.shouControls = false;
    }
  }
};